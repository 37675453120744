<template>
  <el-container class="main-container">
    <el-card>
      <el-row>
        <el-col :span="4">
          <ele-select v-model="queryInfo.requestType"
            placeholder="操作筛选"
            :options="requestTypes"
            @change="query"></ele-select>
        </el-col>
      </el-row>
      <ele-table
        :columns-desc="columns"
        :is-show-index="true"
        :request-fn="getOperatingLogs"
        :is-show-top-delete="false"
        :is-show-right-delete="false"
        ref="table"></ele-table>
    </el-card>
  </el-container>
</template>

<script>
import { operatingTypes, responseTypes } from '@/assets/js/constant'
export default {
  data() {
    return {
      queryInfo: {
        requestType: '',
        responseType: ''
      },
      requestTypes: [],
      columns: {
        userName: {
          text: '操作员'
        },
        operatingType: {
          text: '操作类型',
          type: 'status',
          options: operatingTypes
        },
        requestUrl: {
          text: '请求地址'
        },
        requestMethod: {
          text: '请求方法'
        },
        requestBody: {
          text: '请求参数'
        },
        timeConsumed: {
          text: '耗时'
        },
        responseType: {
          text: '响应类型',
          type: 'status',
          options: responseTypes
        },
        responseMessage: {
          text: '响应信息'
        }
      }
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions() {
      const { data: res } = await this.$api.getOptions()
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.requestTypes = res.data.operatingTypes
    },
    query() {
      this.$refs.table.getData()
    },
    async getOperatingLogs(params) {
      const { data: res } = await this.$api.getOperatingLogs(Object.assign(params, this.queryInfo))
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      return res.data
    }
  }
}
</script>

<style lang="less" scoped></style>
